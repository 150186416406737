<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:status="scope")
      template(v-if="scope.row['status']")
        el-tag(:type="tagFor(scope.row['status'])" v-if="scope.row['status'] == 'completed'") {{ `${_.startCase(scope.row['status'])} in ${_.ceil(scope.row.process.time)} sec` }}
        el-progress(:percentage="calculatePercentage(scope.row.process)" v-else)
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,

  data() {
    return {}
  },

  methods: {
    isDisabledAction(action, row) {
      if(action.name == 'download' && row.status == 'in_progress') {
        return true
      } else {
        return false
      }
    },

    tagFor(status) {
      let tagName
      switch(status) {
        case 'completed':
          tagName = 'success'
          break
        case 'in_progress':
          tagName = 'warning'
          break
      }

      return tagName
    },

    async actionDownload(row) {
      try {
        this.loading = true
        const query = {
          content: true,
          __args: { id: row.id }
        }
        const response = await this.graphql(this.config.graphql.actions.download, query, 'collection')

        if (response.content) {
          const blob = new Blob([response.content], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = [row['setting']['name'], row['setting']['currency'], row['createdAt']].join('__')
          link.click()
          URL.revokeObjectURL(link.href)
        }
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
      this.$nextTick(() => {
        this.loading = false
      })
    },

    calculatePercentage(data) {
      return (data.completed / data.total) * 100
    }
  }

}
</script>
