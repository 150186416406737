import currencies_list from '@/settings/modules/avia/general/currencies_list'

export default {
  name: 'settings',
  items: [
    {
      name: 'settings_list',
      endpoint: '/persistance/default',
      pagination: true,
      autoLoad: true,
      type: 'graphql',
      graphql: {
        name: 'radarSettings',
        queries: {
          index: {
            id: true,
            name: true,
            active: true,
            daysBeforeDepartures: true,
            daysAfterDepartures: true,
            refid: true,
            fixedAviaConfigs: true,
            frequency: true,
            routes: true,
            currency: true,
            startDate: true,
            lastRun: true,
            createdAt: true,
            updatedAt: true,
            aviaConfigGroup: {
              id: true,
              name: true
            },
          }
        },
        actions: {
          run: 'radarSettingRun'
        },
        preloadFields: ['aviaConfigGroups{id name}', 'trafficSourceList{id name}', 'aviaConfigs{id name}']
      },
      preload: true,
      preloadStores: ['aviaConfigGroups', 'trafficSourceList', 'aviaConfigs'],
      table: {
        class: 'radar-settings-table',
        items: [
          'selection',
          { 
            name: 'id',
            width: '50px',
            sortable: false
          },
          {
            name: 'name',
            width: '275px',
            sortable: false
          },
          {
            name: 'aviaConfigGroup.name',
            width: '275px',
            sortable: false
          },
          {
            name: 'refid',
            format: 'array_from_instance',
            storeKey: 'trafficSourceList',
            storeLabelKeys: ['id', 'name'],
            type: 'tagList',
            sortable: false
          },
          {
            name: 'startDate',
            format: 'formatted_date_time',
            sortable: false,
            width: '75px'
          },
          {
            name: 'lastRun',
            format: 'formatted_date_time',
            sortable: false,
            width: '75px'
          },
          {
            name: 'frequency',
            width: '175px',
            type: 'tag',
            tagType: 'success',
            format: 'start_case',
            sortable: false
          },
          {
            name: 'active',
            type: 'switch',
            width: '75px',
            sortable: false
          },
          {
            name: 'actions',
            width: '100px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          },
          {
            name: 'run',
            icon: 'el-icon-video-play',
            type: 'success',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Radar::SettingRelation'
            }
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          'name',
          {
            name: 'radarAviaConfigGroupId',
            type: 'select',
            storeKey: 'aviaConfigGroups',
            storeLabelKeys: ['id', 'name']
          },
          {
            name: 'fixedAviaConfigs',
            type: 'multiselect',
            format: 'array_of_integer',
            storeKey: 'aviaConfigs',
            storeLabelKeys: ['id', 'name'],
            multipleLimit: 5,
            required: false
          },
          {
            name: 'refid',
            type: 'multiselect',
            format: 'array_of_integer',
            storeKey: 'trafficSourceList',
            storeLabelKeys: ['id', 'name']
          },
          {
            name: 'daysBeforeDepartures',
            type: 'input',
            valueType: 'array_of_integer'
          },
          {
            name: 'daysAfterDepartures',
            type: 'input',
            valueType: 'array_of_integer',
            required: false
          },
          {
            name: 'frequency',
            type: 'select',
            valueType: 'string',
            storeLabelKey: 'name',
            store: [
              {
                id: 'dailly',
                name: 'Dailly'
              },
              {
                id: 'once_per_three_days',
                name: 'Once per three days'
              },
              {
                id: 'once_per_week',
                name: 'Once per week'
              }
            ]
          },
          {
            name: 'currency',
            type: 'select',
            default: 'EUR',
            store: currencies_list
          },
          {
            name: 'startDate',
            type: 'date'
          },
          {
            name: 'active',
            type: 'switch'
          },
          {
            name: 'routes',
            type: 'jsoneditor',
            options: {
              mode: 'code'
            }
          }
        ]
      }
    }
  ]
}
